import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import FilledInput from "@material-ui/core/FilledInput"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import Button from "@material-ui/core/Button"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import * as EmailValidator from "email-validator"

import { MAIL_SENDING } from "../constants/mail"


const useStyles = makeStyles(theme => ({
    actions: {
        marginTop: theme.spacing(4),
    },
    formControl: {
        marginTop: theme.spacing(2),
    },
    input: {
        borderRadius: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}))

const ContactFormField = ({ error, ...props }) => {
    const classes = useStyles()
    const placeholder = props.placeholder ? props.placeholder : ""
    const required = props.required ? true : false

    return (
        <FormControl fullWidth className={classes.formControl} variant="filled">
            <InputLabel required={required} htmlFor={ props.label.toLowerCase() } color="secondary">
                { props.label }
            </InputLabel>
            <FilledInput
                id={ props.label.toLowerCase() }
                color="secondary"
                placeholder={ placeholder }
                autoComplete="off"
                classes={{
                    root: classes.input, 
                }}
                {...props}
            />
            <FormHelperText>{ error ? error : " " }</FormHelperText>
        </FormControl>
    )
}

const ContactForm = (props) => {
    const classes = useStyles()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [errors, setErrors] = useState({})

    const onSubmit = (e) => {
        e.preventDefault()

        // Validate the form
        let formErrors = {}
        if (!validateEmail(email)) {
            formErrors["email"] = "*Invalid email"
        }
       
        // Process the form
        const data = new FormData()
        data.append("name", name)
        data.append("email", email)
        data.append("message", message)
        const hasErrors = Object.keys(formErrors).length > 0
        hasErrors ? setErrors(formErrors) : props.onSend(data)
    }

    const validateEmail = (value) => {
        return EmailValidator.validate(value)
    }

    return (
        <form onSubmit={ onSubmit }>
            <ContactFormField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            
            <ContactFormField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={errors.email}
                required
            />
            
            <ContactFormField
                label="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={8}
                required
            />

            <div className={classes.actions}>
                <Button 
                    type="submit"
                    color="secondary" 
                    variant="contained" 
                    disableElevation
                >
                    Send
                </Button>
            </div>

            <Backdrop
                open={props.mailStatus===MAIL_SENDING}
                className={classes.backdrop}
            >
                <CircularProgress />
            </Backdrop>
        </form>
    )
}

export default ContactForm
