import React, { useState } from "react"
import axios from "axios"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import { MAIL_SENDING, MAIL_SENT, MAIL_ERROR } from "../constants/mail"

const instance = axios.create({
    baseURL: "https://api.swizzostudios.com",
    timeout: 5000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
})

const ContactPage = () => {
    const [mailStatus, setMailStatus] = useState(null)

    const handleOnSend = (data) => {
        setMailStatus(MAIL_SENDING)
        instance.post("/contact", data)
            .then(res => setMailStatus(MAIL_SENT))
            .catch(error => {
                console.log(error)
                setMailStatus(MAIL_ERROR)
            })
    }
    return (
        <Layout>
            <SEO title="Contact" />
            <Box mt={8}>
                { mailStatus === MAIL_SENT
                    ? (
                        <Container maxWidth="sm">
                            <Typography variant="h4" align="center" gutterBottom>
                                Thank you for your inquiry!
                            </Typography>
                            <Typography variant="body1" align="center" gutterBottom>
                                We will get back to you as soon as we can
                            </Typography>
                        </Container>
                    )
                    : (
                        <Container maxWidth="sm">
                            <Typography variant="h2" align="center" gutterBottom>
                                Contact Us
                            </Typography>
                            <ContactForm onSend={handleOnSend} mailStatus={mailStatus}/>
                            { mailStatus === MAIL_ERROR && (
                                <Typography variant="caption">
                                    Error sending message
                                </Typography>
                            )}
                        </Container>
                    )
                }
            </Box>
        </Layout> 
    )
}   

export default ContactPage
